import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Config } from "../../../site.config"
import { Grid } from "@mui/material"
import { Button } from "@mui/material"
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart"

export const Fformen = props => {
  const { product } = props
  const data = useStaticQuery(graphql`
    {
      img1: file(relativePath: { eq: "top/fformen1.jpg" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(width: 230, layout: FIXED)
        }
      }
      img2: file(relativePath: { eq: "top/fformen2.jpg" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(width: 230, layout: FIXED)
        }
      }
      img3: file(relativePath: { eq: "top/fformen3.jpg" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(width: 230, layout: FIXED)
        }
      }
      img4: file(relativePath: { eq: "top/fformen5.jpg" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(width: 230, layout: FIXED)
        }
      }
    }
  `)

  return (
    <Grid container spacing={1} id={product.goodsHeaderId}>
      <Grid item xs={6} md={3}>
        <form method="post" action={process.env.CART_URL}>
          <input type="hidden" name="q" value="1" />
          <input type="hidden" name="m" value="1" />
          <input type="hidden" name="h" value={product.goodsHeaderId} />
          <input type="hidden" name="i" value={product.variants[0].goodsId} />
          <input type="hidden" name="company_id" value={Config.companyId} />
          <input
            type="hidden"
            name="deliver_trader_id"
            value={Config.deliverTraderId}
          />
          <input
            type="image"
            style={{ width: "100%" }}
            src={data.img1.publicURL}
          />

          <div className="text-sm mt-2 font-bold">
            {product.name} {product.variants[0].name}
          </div>
          <div className="text-sm mt-2 text-right">
            {product.variants[0].price.toLocaleString()}円（税込）
          </div>
          <div className="text-center p-2">
            <Button
              type="submit"
              variant="contained"
              color="warning"
              startIcon={<AddShoppingCartIcon />}
            >
              購入へ
            </Button>
          </div>
        </form>
      </Grid>

      <Grid item xs={6} md={3}>
        <form method="post" action={process.env.CART_URL}>
          <input type="hidden" name="q" value="1" />
          <input type="hidden" name="m" value="1" />
          <input type="hidden" name="h" value={product.goodsHeaderId} />
          <input type="hidden" name="i" value={product.variants[1].goodsId} />
          <input type="hidden" name="company_id" value={Config.companyId} />
          <input
            type="hidden"
            name="deliver_trader_id"
            value={Config.deliverTraderId}
          />
          <input
            type="image"
            style={{ width: "100%" }}
            src={data.img2.publicURL}
          />

          <div className="text-sm mt-2 font-bold">
            {product.name} {product.variants[1].name}
          </div>
          <div className="text-sm mt-2 text-right">
            {product.variants[1].price.toLocaleString()}円（税込）
          </div>
          <div className="text-center p-2">
            <Button
              type="submit"
              variant="contained"
              color="warning"
              startIcon={<AddShoppingCartIcon />}
            >
              購入へ
            </Button>
          </div>
        </form>
      </Grid>

      <Grid item xs={6} md={3}>
        <form method="post" action={process.env.CART_URL}>
          <input type="hidden" name="q" value="1" />
          <input type="hidden" name="m" value="1" />
          <input type="hidden" name="h" value={product.goodsHeaderId} />
          <input type="hidden" name="i" value={product.variants[2].goodsId} />
          <input type="hidden" name="company_id" value={Config.companyId} />
          <input
            type="hidden"
            name="deliver_trader_id"
            value={Config.deliverTraderId}
          />
          <input
            type="image"
            style={{ width: "100%" }}
            src={data.img3.publicURL}
          />

          <div className="text-sm mt-2 font-bold">
            {product.name} {product.variants[2].name}
          </div>
          <div className="text-sm mt-2 text-right">
            {product.variants[2].price.toLocaleString()}円（税込）
          </div>
          <div className="text-center p-2">
            <Button
              type="submit"
              variant="contained"
              color="warning"
              startIcon={<AddShoppingCartIcon />}
            >
              購入へ
            </Button>
          </div>
        </form>
      </Grid>

      <Grid item xs={6} md={3}>
        <form method="post" action={process.env.CART_URL}>
          <input type="hidden" name="q" value="1" />
          <input type="hidden" name="m" value="1" />
          <input type="hidden" name="h" value={product.goodsHeaderId} />
          <input type="hidden" name="i" value={product.variants[3].goodsId} />
          <input type="hidden" name="company_id" value={Config.companyId} />
          <input
            type="hidden"
            name="deliver_trader_id"
            value={Config.deliverTraderId}
          />
          <input
            type="image"
            style={{ width: "100%" }}
            src={data.img4.publicURL}
          />

          <div className="text-sm mt-2 font-bold">
            {product.name} {product.variants[3].name}
          </div>
          <div className="text-sm mt-2 text-right">
            {product.variants[3].price.toLocaleString()}円（税込）
          </div>
          <div className="text-center p-2">
            <Button
              type="submit"
              variant="contained"
              color="warning"
              startIcon={<AddShoppingCartIcon />}
            >
              購入へ
            </Button>
          </div>
        </form>
      </Grid>
    </Grid>
  )
}

export default Fformen
