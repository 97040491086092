import React, { useEffect, useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { Link as Scroll } from "react-scroll"
import { Grid, List, ListItem, ListItemText } from "@mui/material"
import Divider from "@mui/material/Divider"
import { Products, Products2 } from "../../products"
import Footer from "../components/Footer"
import Inquiry from "../components/Inquiry"
import { Erotica, Fusion, Fformen } from "../components/top"

const Top = ({ data }) => {
  const [company, setCompany] = useState({})
  const [clientId, setClientId] = useState(0)
  const [lastName, setLastName] = useState("")
  const [firstName, setFirstName] = useState("")
  const [mailAddress, setMailAddress] = useState("")
  const [openInquiry, setOpenInquiry] = useState(false)

  const location = useLocation()
  const path = location.pathname !== "/" ? 2 : 1
  const apiUrl = `${process.env.API_COMPANY_URL}?ref=top2`

  useEffect(() => {
    fetch(apiUrl)
      .then(x => x.json())
      .then(x => setCompany(x))
  }, [])

  // modal inquiry
  const handleOpenInquiry = () => {
    setOpenInquiry(true)
  }
  // function to handle modal inquiry close
  const handleCloseInquiry = () => {
    setOpenInquiry(false)
  }

  return (
    <>
      <Helmet
        htmlAttributes={{ lang: "ja-jp" }}
        title="ボディカスタム 公式サイト 商品ラインナップ２"
        meta={[
          {
            name: `description`,
            content: "ボディカスタム 公式サイト 商品ラインナップ２",
          },
        ]}
      />
      <div className="mx-auto max-w-4xl min-w-sm bg-white">
        <div>
          <StaticImage
            src="../assets/images/top/top.jpg"
            width={950}
            height={126}
            alt="BodyCustom"
            placeholder="blurred"
            quality="65" // 画質
          />
        </div>
        <Grid container className="p-0 m-0">
          <Grid item xs={12} md={3} className="border-2 p-0">
            <List className="p-0">
              <ListItem className="border-b-2 bg-indigo-800">
                <ListItemText
                  primary="商品一覧"
                  primaryTypographyProps={{
                    color: "white",
                    fontWeight: "medium",
                    variant: "body2",
                    fontWeight: "bold",
                    fontSize: "21px",
                  }}
                />
              </ListItem>
              {path !== 1
                ? Products2.map((product, index) => (
                    <ListItem
                      key={index.toString()}
                      className="border-dotted border-b-2"
                    >
                      <Scroll
                        to={product.goodsHeaderId}
                        smooth={true}
                        duration={600}
                      >
                        <ListItemText
                          primary={product.name}
                          primaryTypographyProps={{
                            color: "primary",
                            variant: "body2",
                            fontWeight: "bold",
                            fontSize: "15px",
                            cursol: "pointer",
                          }}
                        />
                      </Scroll>
                    </ListItem>
                  ))
                : Products.map((product, index) => (
                    <ListItem
                      key={index.toString()}
                      className="border-dotted border-b-2"
                    >
                      <Scroll
                        to={product.goodsHeaderId}
                        smooth={true}
                        duration={600}
                      >
                        <ListItemText
                          primary={product.name}
                          primaryTypographyProps={{
                            color: "primary",
                            variant: "body2",
                            fontWeight: "bold",
                            fontSize: "15px",
                            cursol: "pointer",
                          }}
                        />
                      </Scroll>
                    </ListItem>
                  ))}
            </List>
          </Grid>
          <Grid item xs={12} md={9} className="border-2 p-0">
            <ListItem className="divide-y border-2 bg-indigo-800">
              <ListItemText
                primary="New Item"
                primaryTypographyProps={{
                  color: "white",
                  variant: "body2",
                  fontWeight: "bold",
                  fontSize: "21px",
                }}
              />
            </ListItem>
            <div className="p-2">
              {/* {path === 1 && (
                <>
                  <Sasuke product={Products[0]} />
                  <Divider className="my-2" />
                </>
              )} */}

              {path === 1 && (
                <>
                  <Erotica product={Products[1]} />
                  <Divider className="my-2" />
                </>
              )}
              {path === 1 && (
                <>
                  <Fusion product={Products[2]} />
                  <Divider className="my-2" />
                </>
              )}
              {path === 1 && (
                <>
                  <Fformen product={Products[3]} />
                </>
              )}
              {/* {path === 2 && (
                <>
                  <Viragua product={Products2[0]} />
                  <Divider className="my-2" />
                </>
              )} */}
              {/* {path === 2 && (
                <>
                  <Revvi product={Products2[1]} />
                </>
              )} */}
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="pt-2">
        <Inquiry
          open={openInquiry}
          handleOpen={handleOpenInquiry}
          handleClose={handleCloseInquiry}
          clientId={clientId}
          lastName={lastName}
          firstName={firstName}
          mailAddress={mailAddress}
        />
      </div>
      <Footer company={company} siteData={data.site.siteMetadata} />
      <style jsx="true">
        {`
          body {
            background-color: #f5f5f5;
          }
        `}
      </style>
    </>
  )
}

export default Top

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        copyright
      }
    }
  }
`
